import { Server } from 'miragejs';
const dashboardRoutes = {
  '/analytics/dashboards-urls': {
    get: (schema: any, request) => {
      const params = request.queryParams;
      const limit = params.$limit;
      const organizationId = params.organization_id;

      const dashboardUrls = schema.dashboardUrls.where({ organization_id: organizationId }).models;
      const paginatedDashboardUrls = dashboardUrls.splice(0, limit);
      const mappedDashboardUrls = paginatedDashboardUrls.map(p => ({
        ...p.attrs,
      }));

      return {
        data: mappedDashboardUrls,
        limit,
        skip: 0,
        total: dashboardUrls.length,
      };
    },
  },
};

const allRoutes = {
  ...dashboardRoutes,
};

export const setupRoutes = (server: Server) => {
  Object.entries(allRoutes).forEach(([path, methods]) => {
    Object.entries(methods).forEach(([method, route]) => {
      server[method](path, route);
    });
  });
};
