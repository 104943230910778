import React from 'react';
import { NavLink } from 'react-router-dom';
import { DidomiNavTabHeader, DidomiNavTabHeaders, DidomiNavTabHeading, DidomiNavTabContent, DidomiNavTabContents, DidomiNavTabs } from '@didomi/ui-atoms-react';

interface DashboardNavTabsProps {
  hasCMPDashboards?: boolean;
  hasACMDashboards?: boolean;
  hasPMPDashboards?: boolean;
}

const DashboardNavTabs = ({ hasCMPDashboards = false, hasACMDashboards = false, hasPMPDashboards = false }: DashboardNavTabsProps): JSX.Element => {
  return (
    <DidomiNavTabs className="mb-8">
      <DidomiNavTabHeading>
        <DidomiNavTabHeaders>
          {hasCMPDashboards && (
            <DidomiNavTabHeader>
              <NavLink activeClassName="didomi-active-tab" data-testid="nav-link-cmp" data-cy="cmp" to="/cmp">
                Consent Management Platform
              </NavLink>
            </DidomiNavTabHeader>
          )}
          {hasPMPDashboards && (
            <DidomiNavTabHeader>
              <NavLink activeClassName="didomi-active-tab" data-testid="nav-link-pmp" data-cy="pmp" to="/pmp">
                Preference Management Platform
              </NavLink>
            </DidomiNavTabHeader>
          )}
          {hasACMDashboards && (
            <DidomiNavTabHeader>
              <NavLink activeClassName="didomi-active-tab" data-testid="nav-link-acm" data-cy="acm" to="/acm">
                Compliance Report
              </NavLink>
            </DidomiNavTabHeader>
          )}
        </DidomiNavTabHeaders>
      </DidomiNavTabHeading>
      <DidomiNavTabContents>
        <DidomiNavTabContent></DidomiNavTabContent>
      </DidomiNavTabContents>
    </DidomiNavTabs>
  );
};

export default DashboardNavTabs;
