import { addAxiosResponseInterceptors } from '@didomi/helpers';
import { useAuthToken, useAuthMethods } from '@didomi/utility-react';
import axios, { AxiosInstance } from 'axios';
import QueryString from 'qs';

const axiosWithLogoutInstance = axios.create();
let axiosWithLogoutInterceptors: AxiosInstance;

export const useAxiosWithLogOut = () => {
  const token = useAuthToken();
  const { logout } = useAuthMethods();

  // Alter defaults after instance has been created
  axiosWithLogoutInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axiosWithLogoutInstance.defaults.paramsSerializer = params => QueryString.stringify(params);

  if (axiosWithLogoutInterceptors) {
    return axiosWithLogoutInterceptors;
  }

  axiosWithLogoutInterceptors = addAxiosResponseInterceptors(axiosWithLogoutInstance, { onExpiredToken: logout });
  return axiosWithLogoutInterceptors;
};
