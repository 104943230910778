import { Factory } from 'miragejs';
import { generateUniqueId } from '@utils';
import { DashboardUrlCategory, DashboardUrlType, DashboardUrlProduct, DashboardUrlAggregationPeriod } from '@enums';
export const factories = {
  languages: Factory.extend({
    id() {
      return generateUniqueId('preference-value');
    },
  }),
  dashboardUrl: Factory.extend({
    id() {
      return generateUniqueId('dashboard-urls');
    },
    name(i) {
      return `ANALYTICS ${i}`;
    },
    url: 'https://dashboards.cluvio.com/dashboards/w13y-jwdv-5p7r/shared?sharingToken=f04be103-f7b9-4491-a5e7-3f8623a7b0b2&sharingSecret=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaGFyaW5nX3Rva2VuIjoiZjA0YmUxMDMtZjdiOS00NDkxLWE1ZTctM2Y4NjIzYTdiMGIyIiwiZXhwIjoxNjQ4NTUwNTUwLCJmaXhlZF9wYXJhbWV0ZXJzIjp7ImFwaWtleSI6IjA0ZGZhMGNhLTU5NTAtNGExOC04NWY1LTVkMjIyZDgwNDdhYiJ9LCJpYXQiOjE2NDg0NjQxNTB9.1fQNCwnU7AAi9_d3UmjUslXP_RGHvmOs0kpOiDlmYII',
    category: DashboardUrlCategory.ANALYTICS,
    type: DashboardUrlType.CLUVIO,
    product: DashboardUrlProduct.CMP,
    description(i) {
      return `The best analytics dashboard ever ${i}`;
    },
    aggregation_period: DashboardUrlAggregationPeriod.HOURLY,
    illustration_ref(i) {
      return `https://img.com/${i}`;
    },
  }),
};

export const createDefaultDashboardUrls = (server, defaultOrganization = 'didomi') => {
  server.create('dashboardUrl', {
    id: 'dashboard-1',
    name: 'Exploration',
    aggregation_period: DashboardUrlAggregationPeriod.HOURLY,
    category: DashboardUrlCategory.ANALYTICS,
    description: 'An up close view of your performances down to the hourly level.',
    order: 6,
    product: DashboardUrlProduct.CMP,
    url: 'https://dashboards.cluvio.com/dashboards/9x36-z25k-e67r/shared?sharingToken=c675b10d-b630-4780-af1f-9e7646bf8f6c',
    illustration_ref:
      'https://zeroheight-uploads.s3-accelerate.amazonaws.com/e55fe33058be1b1c105839?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXTVUC4XZENV3LPQ%2F20220329%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T121008Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a37614b028411c18a893cd7b85e15d8292aa5df524a3d90e0020e2fc0a9460e2',
    organization_id: defaultOrganization,
  });
  server.create('dashboardUrl', {
    name: 'Custom export',
    aggregation_period: DashboardUrlAggregationPeriod.HOURLY,
    category: DashboardUrlCategory.ANALYTICS,
    description: 'Select a period and dimensions and export a custom report.',
    order: 8,
    product: DashboardUrlProduct.CMP,
    url: 'https://dashboards.cluvio.com/dashboards/9x36-z25k-e67r/shared?sharingToken=c675b10d-b630-4780-af1f-9e7646bf8f6c',
    illustration_ref:
      'https://zeroheight-uploads.s3-accelerate.amazonaws.com/9fef33c3305d0b2f451f6d?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXTVUC4XZENV3LPQ%2F20220329%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T121008Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a8491f664f7f37eda83303fc16351b277a691659ad8b12207a09cc7ecc65932a',
    organization_id: defaultOrganization,
  });
  server.create('dashboardUrl', {
    name: 'App Sessions',
    aggregation_period: DashboardUrlAggregationPeriod.WEEKLY,
    category: DashboardUrlCategory.ANALYTICS,
    description: 'Native application traffic performance dashboard',
    order: 5,
    product: DashboardUrlProduct.PMP,
    url: 'https://dashboards.cluvio.com/dashboards/9x36-z25k-e67r/shared?sharingToken=c675b10d-b630-4780-af1f-9e7646bf8f6c',
    illustration_ref:
      'https://zeroheight-uploads.s3-accelerate.amazonaws.com/1e22d8e42363a934937525?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXTVUC4XZENV3LPQ%2F20220329%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T121008Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=d3dcca876c636fb612fcc0d0a3c2697efcc17181442fa14b5075456801133527',
    organization_id: defaultOrganization,
  });
  server.create('dashboardUrl', {
    id: 'ab-dashboard',
    name: 'AB Test',
    aggregation_period: DashboardUrlAggregationPeriod.HOURLY,
    category: DashboardUrlCategory.ANALYTICS,
    description: 'Measure notice alternative version impact on performance.',
    order: 7,
    product: DashboardUrlProduct.CMP,
    url: 'https://dashboards.cluvio.com/dashboards/9x36-z25k-e67r/shared?sharingToken=c675b10d-b630-4780-af1f-9e7646bf8f6c',
    illustration_ref: 'ab-test',
    organization_id: defaultOrganization,
  });
  server.create('dashboardUrl', {
    name: 'Tech',
    aggregation_period: DashboardUrlAggregationPeriod.WEEKLY,
    category: DashboardUrlCategory.ANALYTICS,
    description: 'Deep dive and explore data by tech environments.',
    order: 3,
    product: DashboardUrlProduct.PMP,
    url: 'https://dashboards.cluvio.com/dashboards/9x36-z25k-e67r/shared?sharingToken=c675b10d-b630-4780-af1f-9e7646bf8f6c',
    illustration_ref:
      'https://zeroheight-uploads.s3-accelerate.amazonaws.com/b43ff040c7ad1938215581?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXTVUC4XZENV3LPQ%2F20220329%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T121008Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0eeaae41185265288fb220b4966a2b3949969ee3b9191a8133140f2f9ec08da0',
    organization_id: defaultOrganization,
  });
  server.create('dashboardUrl', {
    name: 'Webpage views',
    aggregation_period: DashboardUrlAggregationPeriod.WEEKLY,
    category: DashboardUrlCategory.ANALYTICS,
    description: 'Web traffic performance analytics.',
    order: 4,
    product: DashboardUrlProduct.CMP,
    url: 'https://dashboards.cluvio.com/dashboards/9x36-z25k-e67r/shared?sharingToken=c675b10d-b630-4780-af1f-9e7646bf8f6c',
    illustration_ref:
      'https://zeroheight-uploads.s3-accelerate.amazonaws.com/1665f7ed422192378bbc6b?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXTVUC4XZENV3LPQ%2F20220329%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T121008Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a0ba165c6316510b87b5a5e5db159c4f1b1eb8e7906aa34323abb82a0d366525',
    organization_id: defaultOrganization,
  });
  server.create('dashboardUrl', {
    name: 'Countries',
    aggregation_period: DashboardUrlAggregationPeriod.WEEKLY,
    category: DashboardUrlCategory.ANALYTICS,
    description: 'Provides an overview of the performances at the country level.',
    order: 2,
    product: DashboardUrlProduct.CMP,
    url: 'https://dashboards.cluvio.com/dashboards/9x36-z25k-e67r/shared?sharingToken=c675b10d-b630-4780-af1f-9e7646bf8f6c',
    illustration_ref:
      'https://zeroheight-uploads.s3-accelerate.amazonaws.com/61f50677ae5b25619b6086?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXTVUC4XZENV3LPQ%2F20220329%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T121008Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=5ea9316ded8b6e21a8a2b0118f489e92d541cdafcb8987c283a76bb78fac0cd5',
    organization_id: defaultOrganization,
  });
  server.create('dashboardUrl', {
    name: 'Domains & Apps',
    aggregation_period: DashboardUrlAggregationPeriod.WEEKLY,
    category: DashboardUrlCategory.ANALYTICS,
    description: "Provides an overview of the domain's performance.",
    order: 1,
    product: DashboardUrlProduct.CMP,
    url: 'https://dashboards.cluvio.com/dashboards/9x36-z25k-e67r/shared?sharingToken=c675b10d-b630-4780-af1f-9e7646bf8f6c',
    illustration_ref:
      'https://zeroheight-uploads.s3-accelerate.amazonaws.com/39296ea6f1607796caa65f?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXTVUC4XZENV3LPQ%2F20220329%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T121008Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b447a5e35e82066531f259107e91f982298e43053e416d5cefdfd4480b776ddc',
    organization_id: defaultOrganization,
  });
  server.create('dashboardUrl', {
    name: 'Compliance Reports',
    aggregation_period: DashboardUrlAggregationPeriod.WEEKLY,
    category: DashboardUrlCategory.ANALYTICS,
    description: 'Deep dive and explore compliance report data.',
    order: 3,
    product: DashboardUrlProduct.ACM,
    url: 'https://dashboards.cluvio.com/dashboards/9x36-z25k-e67r/shared?sharingToken=c675b10d-b630-4780-af1f-9e7646bf8f6c',
    illustration_ref:
      'https://zeroheight-uploads.s3-accelerate.amazonaws.com/b43ff040c7ad1938215581?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXTVUC4XZENV3LPQ%2F20220329%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220329T121008Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0eeaae41185265288fb220b4966a2b3949969ee3b9191a8133140f2f9ec08da0',
    organization_id: defaultOrganization,
  });
};
