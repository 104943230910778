import { useCallback, useEffect, useMemo } from 'react';
import { useSnackbar } from '@didomi/utility-react';
import { useGetDashboards } from './data/useGetDashboards';
import { DashboardUrl } from '../interfaces/dashboard-url.interface';

const useDashboards = () => {
  const { displaySnackbar, cancelActiveSnackbar } = useSnackbar();

  const {
    isLoading: loading,
    data,
    refetch: refetchAnalyticsDashboards,
    error: errorLoadingDashboards,
  } = useGetDashboards({
    onError: () => {
      displaySnackbar('Analytics can not be loaded', {
        variant: 'error',
        title: 'Sorry, an error occurred:',
        permanent: true,
        action: {
          action: async () => {
            await cancelActiveSnackbar();
            refetchAnalyticsDashboards();
          },
          name: 'Reload',
        },
        secondaryAction: {
          action: () => {},
          name: 'Close',
          closeInAction: true,
        },
      });
    },
  });

  // This `useEffect` is necessary to hide the snackbar when the user navigates outside of the analytics dashboard.
  useEffect(() => {
    return () => {
      cancelActiveSnackbar();
    };
  }, [cancelActiveSnackbar]);

  const dashboards = useMemo(() => data?.data || [], [data]);

  /**
   * Get an array of dashboard data.
   */
  const sortedDashboards: {
    cmp: DashboardUrl[];
    pmp: DashboardUrl[];
    acm: DashboardUrl[];
  } = useMemo(() => {
    return dashboards.reduce(
      (acc, dashboard) => {
        acc[dashboard.product].push(dashboard);
        return acc;
      },
      { cmp: [], pmp: [], acm: [] },
    );
  }, [dashboards]);

  /**
   * Get the individual dashboard based on it's id.
   */
  const getDashboard = useCallback(
    (id: string): DashboardUrl | null => {
      return loading ? null : dashboards.find(dashboard => dashboard.id === id);
    },
    [loading, dashboards],
  );

  return {
    error: errorLoadingDashboards,
    loading,
    getDashboard,
    sortedDashboards,
  };
};

export default useDashboards;
