import { PaginatedResponse } from '@types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { DashboardUrl } from '../../interfaces/dashboard-url.interface';
import { apiRoutes } from '../../constants/apiRoutes.constants';

export const useGetDashboards = (options?: UseQueryOptions<PaginatedResponse<DashboardUrl>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<DashboardUrl>>(apiRoutes.dashboardUrls, {
      params: {
        organization_id: organizationId,
        $limit: 100,
      },
    });
    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<DashboardUrl>>(
      [
        'dashboardUrls',
        {
          organization_id: organizationId,
        },
      ],
      fetch,
      options,
    ),
  };
};
