import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { UtilityProvider, useActiveOrganization } from '@didomi/utility-react';
import { connect } from '@didomi/ui-foundation';
import * as utility from '@didomi/utility';
import { makeServer } from './__mocks__/server';
import App from './pages/App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Setup Twind for analytics only
connect('.analytics-root');

const queryClient = new QueryClient({
  logger: {
    error: () => {},
    log: () => {},
    warn: () => {},
  },
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      cacheTime: 0,
    },
  },
});

// @ts-ignore
if (window.Cypress) {
  makeServer({ defaultOrganization: 'pmp-uat' });
} else {
  // Uncomment next line if you want to run mirage
  // makeServer({ environment: 'development', defaultOrganization: 'didomi' });
}

/**
 * Root Component
 * Takes care of initializing the utilities
 */
const Root = (): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <UtilityProvider utility={utility}>
        <Router />
      </UtilityProvider>
    </QueryClientProvider>
  );
};
/**
 * Router Component
 * Takes care of initializing the router
 *
 * This component is separated from the Root component as the
 * useActiveOrganization hook needs the Utility povider to be loaded to work properly
 */
const Router = (): JSX.Element => {
  const { organizationId } = useActiveOrganization();
  if (window.location.pathname === '/') {
    window.location.href = `/${organizationId}/analytics`;
  }

  return (
    <BrowserRouter basename={`/${organizationId}/analytics`}>
      <App />
    </BrowserRouter>
  );
};

export { Root };
