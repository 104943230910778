import React from 'react';
import { DidomiSmallPush } from '@didomi/ui-atoms-react';

interface InAppHelperProps {
  title: string;
  url: string;
  subtitle?: string;
  'data-testid'?: string;
}

export const InAppHelper = ({ subtitle = 'Good to know!', title, url, 'data-testid': dataTestId = 'in-app-helper' }: InAppHelperProps) => {
  return (
    <a className="outline-none" data-testid={dataTestId} href={url} target="_blank" rel="noopener noreferrer">
      <DidomiSmallPush data-testid={`${dataTestId}-banner`} data-tracking="in-app-helper-link" highlightedText={title} text={subtitle} smallIcon="right-on" />
    </a>
  );
};
