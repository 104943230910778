import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DidomiBackButtonLink, DidomiSecondaryHeader, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { useDashboards, useCluvioDashboardSizeEvent } from '@hooks';
import { ParamTypes } from '@types';
import { InAppHelper } from '@components';

const Dashboard = (): JSX.Element => {
  const history = useHistory();
  const { product, dashboardId } = useParams<ParamTypes>();
  const { height: dashboardHeight } = useCluvioDashboardSizeEvent({ initialHeight: 'calc(100vh - 210px)' });
  const { loading, getDashboard } = useDashboards();
  const dashboard = getDashboard(dashboardId);

  return (
    <DidomiSkeleton variant="layout" isLoading={loading} data-testid={`dashboard-view-loading-${loading}`}>
      <section className="px-12 pt-12 flex flex-col">
        <DidomiSecondaryHeader className="mb-8" title-text={dashboard?.name}>
          <div slot="back-button">
            <DidomiBackButtonLink
              onClick={() => {
                history.push(`/${product}`);
              }}
              text="Back to dashboard list"
            />
          </div>
          {dashboard?.illustration_ref === 'ab-test' && (
            <div slot="actions">
              <InAppHelper data-testid="dashboard-view-ab-banner" title="How to run AB Tests?" url="https://developers.didomi.io/cmp/web-sdk/ab-tests" />
            </div>
          )}
        </DidomiSecondaryHeader>
        <div style={{ height: dashboardHeight }} data-skeleton>
          {dashboard && (
            <iframe
              allowFullScreen
              title={`Dashboard - ${dashboard.name}`}
              frameBorder="0"
              width="100%"
              height="100%"
              id="dashboard-preview"
              data-testid="dashboard-preview"
              src={dashboard.url}
            />
          )}
        </div>
      </section>
    </DidomiSkeleton>
  );
};

export default Dashboard;
