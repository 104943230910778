import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { DashboardList, Dashboard } from '@pages';

const App = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/cmp" />
      </Route>
      <Route exact path="/:product">
        <DashboardList />
      </Route>
      <Route exact path="/:product/:dashboardId">
        <Dashboard />
      </Route>
    </Switch>
  );
};

export default App;
