import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DidomiSkeleton, DidomiFeatureHeader, DidomiContentAnalyticsCard, DidomiIllustration } from '@didomi/ui-atoms-react';
import { useFeatureFlag } from '@didomi/utility-react';
import { DashboardNavTabs } from '@components';
import { useDashboards } from '@hooks';
import { ParamTypes } from '@types';
import { DashboardUrl } from '../../interfaces/dashboard-url.interface';

const LOADING_DASHBOARDS = Array.from({ length: 9 }, (_, index) => ({ id: `loading-dashboard-${index}` }));

const DashboardList = (): JSX.Element => {
  const { product } = useParams<ParamTypes>();
  const history = useHistory();
  const [hasCMPFlag, isLoadingCMPFlag] = useFeatureFlag('consent_management_platform');
  const [hasPMPFlag, isLoadingPMPFlag] = useFeatureFlag('preference_management_platform');
  const [hasACMFlag, isLoadingACMFlag] = useFeatureFlag('compliance_report_v2');
  const { loading, sortedDashboards, error: errorLoadingDashboards } = useDashboards();
  const dashboards: DashboardUrl[] = loading ? LOADING_DASHBOARDS : sortedDashboards[product.toLowerCase()];
  const hasCMPDashboards = hasCMPFlag && sortedDashboards?.cmp?.length > 0;
  const hasPMPDashboards = hasPMPFlag && sortedDashboards?.pmp?.length > 0;
  const hasACMDashboards = hasACMFlag && sortedDashboards?.acm?.length > 0;
  const shouldRenderNavTabs = hasPMPDashboards || hasACMDashboards;
  const isLoading = loading || isLoadingCMPFlag || isLoadingACMFlag || isLoadingPMPFlag;

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoading} data-testid={`dashboard-list-loading-${isLoading}`}>
      <div className="h-full pt-12 mx-12" data-testid="product-page">
        <DidomiFeatureHeader className="w-full min-w-[1084px] block mb-8" titleText="Analytics" name="Analytics">
          <div slot="description" data-testid="dashboard-list-product-header-description" data-cy="product-header--description">
            Get a detailed picture of your consent rates and easily detect unexpected variations in user behavior. Measure the impact of changes and increase consent rates.
          </div>
        </DidomiFeatureHeader>

        {/* Show NavTabs when user is subscribed to PMP and has at least one PMP Analytics Dashboard.  */}
        {isLoading && <DidomiSkeleton isLoading className="h-[63px] w-full mb-8" />}
        {!isLoading && shouldRenderNavTabs && <DashboardNavTabs hasCMPDashboards={hasCMPDashboards} hasACMDashboards={hasACMDashboards} hasPMPDashboards={hasPMPDashboards} />}

        {!errorLoadingDashboards && (
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 pb-20" data-testid="dashboard-list-cards-container">
            {dashboards.map(dashboard => (
              <DidomiContentAnalyticsCard
                data-skeleton
                data-testid="dashboard-list-analytics-card"
                key={dashboard.id}
                id={dashboard.id}
                className="relative"
                onClick={() => history.push(`/${dashboard.product?.toLowerCase()}/${dashboard.id}`)}
                titleText={dashboard.name}
                description={dashboard.description}
                imgSrc={`${CONFIG.assetUri}${dashboard.illustration_ref || 'generic-dashboards'}.svg`}
                ctaText="Discover"
                chip={`Aggregate ${dashboard.aggregation_period?.toLowerCase()}`}
              />
            ))}
          </div>
        )}

        {errorLoadingDashboards && (
          <div className="h-3/5" data-testid="dashboard-list-error-state">
            <div className="h-full w-full flex flex-1 border-1 border-dashed border-neutral-gray-5 rounded-lg items-center justify-center">
              <DidomiIllustration name="list-cannot-be-loaded" className="mb-xs" />
            </div>
          </div>
        )}
      </div>
    </DidomiSkeleton>
  );
};

export default DashboardList;
