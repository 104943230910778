import { useCallback, useState, useEffect } from 'react';

interface initialSizeParams {
  initialWidth?: string;
  initialHeight?: string;
}

interface SizeOutput {
  width: string;
  height: string;
}

const useCluvioDashboardSizeEvent = ({ initialWidth = '', initialHeight = '' }: initialSizeParams): SizeOutput => {
  const [size, setSize] = useState<SizeOutput>({ width: initialWidth, height: initialHeight });

  const handleHeightChange = useCallback((event: any) => {
    if (event && event.origin === 'https://dashboards.cluvio.com') {
      const parsedEventData = JSON.parse(event.data);
      if (parsedEventData.event === 'embedSize') {
        setSize({
          width: `${parsedEventData.params.width}px`,
          height: `${parsedEventData.params.height}px`,
        });
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleHeightChange);
    return () => {
      window.removeEventListener('message', handleHeightChange);
    };
  }, [handleHeightChange]);

  return size;
};

export default useCluvioDashboardSizeEvent;
