import { createServer } from 'miragejs';
import { models } from './models';
import { createDefaultDashboardUrls, factories } from './factories';
import { setupRoutes } from './routes';

type ServerProps = {
  environment?: 'development' | 'test';
  defaultOrganization?: string;
};

export function makeServer({ environment, defaultOrganization }: ServerProps = { environment: 'development', defaultOrganization: 'didomi' }) {
  return createServer({
    environment,
    models,
    factories,
    routes() {
      //@ts-ignore
      this.urlPrefix = CONFIG.apiUrl;
      this.logging = false;

      setupRoutes(this);

      this.passthrough('http://localhost:9000/*');
      this.passthrough('https://api-staging.didomi.io/*');
      this.passthrough('https://auth.staging.didomi.io/*');
      this.passthrough('https://uilib.didomi.io/*');
      this.passthrough('https://*--uilib-didomi.netlify.app/*');
      this.passthrough('https://api.privacy-center.org/v1/*');
      this.passthrough(request => {
        const uiLibBranchPattern = new RegExp(/https:\/\/(.*?)--uilib-didomi.netlify.app\/(.*?)/);
        if (uiLibBranchPattern.test(request.url)) return true;
      });
    },

    seeds(server) {
      createDefaultDashboardUrls(server, defaultOrganization);
    },
  });
}
